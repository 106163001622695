import { FC, PropsWithChildren } from 'react';

import Markup from 'src/components/Markup';
import { ASYNC_HIGHLIGHT, ASYNC_HIGHLIGHT_SHORT } from 'src/components/Markup/AsyncHighlightProcessor';

const AsyncHighlighter: FC<
    {
        short?: boolean;
    } & PropsWithChildren
> = ({ short, children }) => <Markup processors={[short ? ASYNC_HIGHLIGHT_SHORT : ASYNC_HIGHLIGHT]}>{children}</Markup>;

export default AsyncHighlighter;
