import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerBadges } from 'src/models/employer/employerInfo';
import { Badge, BadgeType } from 'src/models/vacancyView.types';

const useGetCompanyHHRatingBadge = (badges?: EmployerBadges, isVacancyPage?: boolean): Badge | undefined => {
    const isZP = useIsZarplataPlatform();
    const userType = useSelector((state) => state.userType);

    if (isZP) {
        return undefined;
    }

    const badge = badges?.badge.find((badge) => badge.type === BadgeType.EmployerHHRating);

    if (!badge) {
        return undefined;
    }

    const utmCampaign = isVacancyPage ? 'card' : 'icon';
    const urlParams = `?utm_source=hh.ru&utm_medium=referral&utm_campaign=${utmCampaign}&utm_term=${userType}`;

    return { ...badge, url: `${badge.url}${urlParams}` };
};

export default useGetCompanyHHRatingBadge;
