import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';
import fetcher from 'src/utils/fetcher';

export default async (vacancyId: number, isQuestion = false): Promise<VacancyChatInfoType | null> => {
    let data: VacancyChatInfoType;
    try {
        data = await fetcher.get('/applicant/vacancy_response/popup', {
            params: {
                isTest: 'no',
                lux: true,
                withoutTest: 'no',
                isCheckingResponseType: true,
                isQuestion,
                vacancyId,
            },
        });
    } catch (_) {
        return null;
    }
    if (Object.keys(data).length === 0) {
        return null;
    }
    return data;
};
