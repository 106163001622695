import { useLayoutEffect, FC, useRef } from 'react';

import verticalFadeIn from 'Modules/verticalFadeIn';
import ApplicantActivityCard from 'src/components/Applicant/ActivityCard';
import { useSelector } from 'src/hooks/useSelector';

import styles from './style.less';

interface ActivityCardAnimatedProps {
    vacancyId: number;
}

const ActivityCardAnimated: FC<ActivityCardAnimatedProps> = ({ vacancyId }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const responseStatuses = useSelector((state) => state.applicantVacancyResponseStatuses);
    const responseStatus = responseStatuses[vacancyId];
    const hasTopics = responseStatus?.negotiations?.topicList.length > 0;

    useLayoutEffect(() => {
        if (containerRef.current && hasTopics) {
            void verticalFadeIn(containerRef.current, {
                hiddenClass: styles.containerHidden,
                propertyList: {
                    height: undefined,
                    paddingTop: 0,
                    paddingBottom: 0,
                    opacity: 1,
                },
            });
        }
    }, [hasTopics]);

    if (!hasTopics) {
        return null;
    }

    return (
        <div ref={containerRef} className={styles.containerHidden}>
            <ApplicantActivityCard variant="response" showScoreChange />
        </div>
    );
};

export default ActivityCardAnimated;
