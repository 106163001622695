/**
 * @deprecated
 */
export enum ScheduleCamelCase {
    FullDay = 'fullDay',
    Shift = 'shift',
    Flexible = 'flexible',
    Remote = 'remote',
    FlyInFlyOut = 'flyInFlyOut',
}

/**
 * @deprecated
 */
export enum ScheduleLowerCase {
    FullDay = 'full_day',
    Shift = 'shift',
    Flexible = 'flexible',
    Remote = 'remote',
    FlyInFlyOut = 'fly_in_fly_out',
}
