import { FC } from 'react';

import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';

export interface AllowReplacementProps {
    title: string;
    toggle: (cleanup?: boolean) => Promise<void>;
    removeNotification: () => void;
}

const AllowReplacement: FC<AllowReplacementProps> = ({ title, toggle, removeNotification }) => (
    <Link
        kind={LinkKind.Tertiary}
        appearance={LinkAppearance.Pseudo}
        onClick={() => {
            void toggle(true);
            removeNotification();
        }}
    >
        {' '}
        {title}
    </Link>
);

export default AllowReplacement;
