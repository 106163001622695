export enum ApplicantSubState {
    Hide = 'HIDE',
    Show = 'SHOW',
}

enum InboxAvailabilityState {
    ArchivedTopic = 'ARCHIVED_TOPIC',
    WithoutInvitation = 'WITHOUT_INVITATION',
    EmptyMessageText = 'EMPTY_MESSAGE_TEXT',
    HugeMessageText = 'HUGE_MESSAGE_TEXT',
    Available = 'AVAILABLE',
    DisabledByEmployer = 'DISABLED_BY_EMPLOYER',
}

export enum EmployerStateType {
    Assessment = 'ASSESSMENT',
    Consider = 'CONSIDER',
    Hired = 'HIRED',
    Interview = 'INTERVIEW',
    Offer = 'OFFER',
    PhoneInterview = 'PHONE_INTERVIEW',
    Response = 'RESPONSE',
    Invitation = 'INVITATION',
    DiscardByEmployer = 'DISCARD_BY_EMPLOYER',
    DiscardByApplicant = 'DISCARD_BY_APPLICANT',
    DiscardNoInteraction = 'DISCARD_NO_INTERACTION',
    DiscardToOtherVacancy = 'DISCARD_TO_OTHER_VACANCY',
    DiscardVacancyClosed = 'DISCARD_VACANCY_CLOSED',
}

const DISCARD_EMPLOYER_STATES = [
    EmployerStateType.DiscardByEmployer,
    EmployerStateType.DiscardByApplicant,
    EmployerStateType.DiscardNoInteraction,
    EmployerStateType.DiscardToOtherVacancy,
    EmployerStateType.DiscardVacancyClosed,
] as const;

export const isDiscardEmployerState = (state: unknown): state is (typeof DISCARD_EMPLOYER_STATES)[number] =>
    !!state && DISCARD_EMPLOYER_STATES.includes(state);

export enum EmployerStateExtName {
    Response = 'response',
    PhoneInterview = 'phone_interview',
    Assessment = 'assessment',
    Interview = 'interview',
    Offer = 'offer',
    Hired = 'hired',
    Consider = 'consider',
    DiscardByEmployer = 'discard_by_employer',
    DiscardByApplicant = 'discard_by_applicant',
    DiscardNoInteraction = 'discard_no_interaction',
    DiscardToOtherVacancy = 'discard_to_other_vacancy',
    DiscardVacancyClosed = 'discard_vacancy_closed',
}

export const UNSUITABLE_EMPLOYER_STATE_EXT_NAMES = [
    EmployerStateExtName.DiscardByEmployer,
    EmployerStateExtName.DiscardByApplicant,
    EmployerStateExtName.DiscardNoInteraction,
    EmployerStateExtName.DiscardToOtherVacancy,
    EmployerStateExtName.DiscardVacancyClosed,
] as const;

export type UnsuitableEmployerStateExtName = (typeof UNSUITABLE_EMPLOYER_STATE_EXT_NAMES)[number];

export enum TopicSource {
    Negotiation = 'NEGOTIATION',
    Chat = 'CHAT',
    PhoneCall = 'PHONE_CALL',
    Vr = 'VR',
}

enum EmployerSubState {
    New = 'NEW',
    Hold = 'HOLD',
}

export enum ResourceType {
    Vacancy = 'VACANCY',
    Resume = 'RESUME',
    Assessment = 'ASSESSMENT',
}

export interface NegotiationTopicFunnelStage {
    employerState: EmployerStateType;
    funnelStageId: number;
    rootStage: boolean;
    substateName: string;
}

interface NegotiationTopic {
    applicantSubState: ApplicantSubState;
    applicantUserId: number;
    archived: boolean;
    chatId: number;
    chatIsArchived: boolean;
    conversationMessagesCount: number;
    conversationUnreadByEmployerCount: number;
    creationTime: string;
    creationTimeMillis: number;
    declineByApplicantAllowed: boolean;
    employerCreationTime: string;
    employerId: number;
    employerLastChangeTime: string;
    employerSubState: EmployerSubState | null;
    employerViolatesRules: boolean;
    hasNewMessages: boolean;
    hasResponseLetter: boolean;
    hasText: boolean;
    id: number;
    inboxAvailabilityState: InboxAvailabilityState;
    initialEmployerState: EmployerStateType;
    initialState: EmployerStateType;
    initialFunnelStage?: NegotiationTopicFunnelStage;
    lastChangeDateTimeExceptEmployerInbox: string;
    lastEmployerState: EmployerStateType;
    lastEmployerStateExtName: EmployerStateExtName;
    lastModified: string;
    lastModifiedMillis: number;
    lastState: EmployerStateType;
    lastFunnelStage?: NegotiationTopicFunnelStage;
    // deprecated Will be removed in next version. Use applicantSubState
    lastSubState?: string | null;
    resources: {
        id: number;
        type: ResourceType;
    }[];
    responseReminderState: null;
    resumeId: number;
    topicSource: TopicSource;
    vacancyId: number;
    vacancyName: string;
    viewedByOpponent: boolean;
    responded: boolean;
    invited: boolean;
    discarded: boolean;
}

export default NegotiationTopic;
