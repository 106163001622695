import Analytics from '@hh.ru/analytics-js';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getUserResumes } from 'src/components/VacancyResponseForm';
import type { ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';

export const sendBottomSheetShownEvent = (vacancyId: number, responseStatus: ShortVacancy): void => {
    if (!responseStatus) {
        return;
    }
    const resumes = getUserResumes(responseStatus);
    Analytics.sendHHEvent('element_shown', {
        elementName: 'vacancy_response_bottom_sheet',
        resumeHash: resumes[0]._attributes.hash,
        vacancyId,
    });
};
