export enum LoopCounterStage {
    Progress = 'progress',
    Success = 'success',
}

const LoopCounterParser = (
    requiredSteps: number,
    stepsCount: number
): { roundCounter: number; completeStepCounter: number; stage: LoopCounterStage } => {
    const roundCounter = Math.floor(stepsCount / requiredSteps);
    const completeStepCounter = Math.trunc(stepsCount - roundCounter * requiredSteps);
    const stage = completeStepCounter === 0 && roundCounter >= 1 ? LoopCounterStage.Success : LoopCounterStage.Progress;
    return { roundCounter, completeStepCounter, stage };
};

export default LoopCounterParser;
