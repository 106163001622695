import { ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction } from 'react';

import { useCdnImageHost } from 'src/hooks/useCdnImageHost';

interface CdnImgProps extends ComponentPropsWithoutRef<'img'> {
    path: string;
}

const CdnImg: ForwardRefRenderFunction<HTMLImageElement, CdnImgProps> = ({ path, ...imgProps }, ref) => {
    const fullPath = useCdnImageHost(path);
    return <img src={fullPath || ''} {...imgProps} ref={ref} />;
};

export default forwardRef(CdnImg);
