import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { MagritteContext } from 'src/hooks/useMagritte';

interface MagritteWrapperProps {
    isEnabled: boolean;
}

const MagritteWrapper: FC<MagritteWrapperProps & PropsWithChildren> = ({ isEnabled, children }) => {
    return (
        <MagritteContext.Provider value={isEnabled}>
            <div className={classnames({ 'magritte-redesign': isEnabled })}>{children}</div>
        </MagritteContext.Provider>
    );
};

export default MagritteWrapper;
