import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    header: 'employer.vacancy.archive.applicantsFeedback.notification.header',
    text: 'employer.vacancy.archive.applicantsFeedback.notification.text',
};

const ArchiveVacancyApplicantsFeedbackSuccess: TranslatedComponent = ({ trls }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.header]}</NotificationHeading>
        {trls[TrlKeys.text]}
    </>
);

const archiveVacancyApplicantsFeedbackSuccess = {
    Element: translation(ArchiveVacancyApplicantsFeedbackSuccess),
    kind: 'ok',
    autoClose: false,
};

export default archiveVacancyApplicantsFeedbackSuccess;
