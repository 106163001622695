import { NotificationKind } from 'bloko/blocks/notificationManager/Notification';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AllowReplacement from 'src/components/VacancyFavorite/AllowReplacement';
import translation from 'src/components/translation';

export enum VacancyFavoriteErrorCode {
    VacancyNotFoundError = 'vacancyNotFoundError',
    VacancyNotAvailableError = 'vacancyNotAvailableError',
    VacancyNotVisibleError = 'vacancyNotVisibleError',
    VacancyArchivedError = 'vacancyArchivedError',
    VacancyAlreadyInFavoriteList = 'vacancyAlreadyInFavoriteList',
    VacancyNotFoundInFavoriteList = 'vacancyNotFoundInFavoriteList',
    GenericError = 'genericError',
    LimitExceededError = 'limitExceededError',
    AllowReplacement = 'allowReplacement',
}

const TrlKeys = {
    vacancyNotFoundError: 'vacancyToFavorite.error.vacancyNotFound',
    vacancyNotAvailableError: 'vacancyToFavorite.error.vacancyNotAvailable',
    vacancyNotVisibleError: 'vacancyToFavorite.error.vacancyNotVisible',
    vacancyArchivedError: 'vacancyToFavorite.error.vacancyArchived',
    vacancyAlreadyInFavoriteList: 'vacancyToFavorite.error.vacancyAlreadyInFavoriteList',
    vacancyNotFoundInFavoriteList: 'vacancyToFavorite.error.vacancyNotFoundInFavoriteList',
    genericError: 'error.500.tryagain',
    limitExceededError: 'vacancyToFavorite.error.limitExceeded',
    allowReplacement: 'vacancysearchresults.vacancy.favorite.error.limitexceeded.proceed',
};

type ErrorProps = {
    code?: VacancyFavoriteErrorCode;
    toggle: (cleanup?: boolean) => Promise<void>;
    removeNotification: () => void;
};

const Error: TranslatedComponent<ErrorProps> = ({ trls, code, toggle, removeNotification }) => {
    return (
        <div>
            {trls[TrlKeys[code || 'genericError']]}
            {code === 'limitExceededError' && (
                <AllowReplacement
                    title={trls[TrlKeys.allowReplacement]}
                    toggle={toggle}
                    removeNotification={removeNotification}
                />
            )}
        </div>
    );
};

export default {
    Element: translation(Error),
    kind: NotificationKind.Error,
};
