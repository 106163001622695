import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Text from 'bloko/blocks/text';

import styles from './styles.less';

type Props = PropsWithChildren<{
    dataQa?: string;
    className?: string;
    Element?: 'span' | 'div';
}>;

const FakeMagrittePrimaryText: FC<Props> = ({ dataQa, className, Element, children }) => {
    return (
        <Text Element={Element} data-qa={dataQa}>
            <span className={classnames(styles.fakeMagrittePrimaryText, className)}>{children}</span>
        </Text>
    );
};

export default FakeMagrittePrimaryText;
