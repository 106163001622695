export const circleComponentData = (viewBox = 0, strokeWidth = 0) => {
    const radiusCircle = viewBox / 2 - strokeWidth / 2;
    const dashArray = Math.PI * (radiusCircle * 2);
    const progress = (value, required) => {
        return (1 - value / required) * dashArray;
    };

    return {
        dashArray,
        computedProgress: (value, required) => progress(value, required),
    };
};

export const applyTemplate = (template, partials, props) => {
    const temporaryContainer = document.createElement('div');
    temporaryContainer.innerHTML = template.render({ ...props }, partials);
    return temporaryContainer.firstChild;
};

export const animate = ({ delay = 0, duration = 0, run }) => {
    const endDuration = +new Date() + duration;
    const delayStart = +new Date() + delay;
    let delayLeft = false;

    const step = () => {
        const current = +new Date();
        const remaining = endDuration - current;

        if (current > delayStart && !delayLeft) {
            delayLeft = true;
            run(0);
            requestAnimationFrame(step);
            return;
        }

        if (duration) {
            const rate = remaining / duration;

            if (remaining <= 60) {
                run(1);
                return;
            }

            run(1 - Math.pow(rate, 3));
        }

        requestAnimationFrame(step);
    };
    step();
};
