import { ReactNode, ReactElement } from 'react';

import { NextFunction } from 'src/components/Markup/makrupTypes';

export default (
    string: string,
    regExp: RegExp,
    wrap: (found: RegExpExecArray) => ReactElement,
    next: NextFunction
): ReactNode[] => {
    const result = [] as ReactNode[];
    let lastIndex = 0;
    let found = regExp.exec(string);
    while (found) {
        result.push(next(string.slice(lastIndex, found.index)));
        result.push(wrap(found));
        lastIndex = regExp.lastIndex;
        found = regExp.exec(string);
    }
    result.push(next(string.slice(lastIndex)));
    return result;
};
