import { forwardRef, ForwardRefRenderFunction } from 'react';
import classnames from 'classnames';

import CircleSvg from 'src/components/Applicant/LoopCounter/CircleSVG';

import styles from './loop-counter.less';

interface StyledCircleProps {
    offset: number;
    progress: string;
    isMagritteExp?: boolean;
    isSmall?: boolean;
}

const StyledCircle: ForwardRefRenderFunction<HTMLDivElement, StyledCircleProps> = (
    { offset, progress, isMagritteExp, isSmall },
    ref
) => (
    <div
        className={classnames(styles.counterCircle, {
            [styles.counterCircleMagritte]: isMagritteExp,
            [styles.counterCircleMagritteSmall]: isSmall,
        })}
        ref={ref}
        data-progress={progress}
    >
        <CircleSvg offset={offset} isMagritteExp={isMagritteExp} />
    </div>
);
export default forwardRef(StyledCircle);
