import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './loop-counter.less';

interface CircleSvgProps {
    offset: number;
    isMagritteExp?: boolean;
}

const CircleSvg: FC<CircleSvgProps & PropsWithChildren> = ({ offset = 0, isMagritteExp }) => {
    const radius = 28;
    const strokeDasharray = Math.PI * (radius * 2);

    if (isMagritteExp) {
        return (
            <svg width="100%" height="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle
                    r="30"
                    cx="32"
                    cy="32"
                    fill="none"
                    className={classnames(styles.counterCirclePrimary, styles.counterCirclePrimaryMagritte)}
                    strokeDasharray="0"
                    strokeDashoffset="0"
                    strokeWidth="4"
                />
                <circle
                    r="30"
                    cx="32"
                    cy="32"
                    fill="none"
                    className={classnames(styles.counterCircleSecondary, styles.counterCircleSecondaryMagritte)}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={offset}
                    transform="rotate(-90,32,32)"
                    strokeWidth="4"
                    strokeLinecap="round"
                />
            </svg>
        );
    }

    return (
        <svg width="100%" height="100%" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle
                r={radius}
                cx="30"
                cy="30"
                fill="none"
                className={styles.counterCirclePrimary}
                strokeDasharray="0"
                strokeDashoffset="0"
                strokeWidth="4"
            />
            <circle
                r={radius}
                cx="30"
                cy="30"
                fill="none"
                className={styles.counterCircleSecondary}
                strokeDasharray={strokeDasharray}
                strokeDashoffset={offset}
                transform="rotate(-90,30,30)"
                strokeWidth="4"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default CircleSvg;
