import classnames from 'classnames';

import Button from 'bloko/blocks/button';
import capitalize from 'bloko/common/capitalize';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ChatBotMessengerTypes } from 'src/models/chatBot';

import styles from './button.less';

const TrlKeys = {
    [ChatBotMessengerTypes.Viber]: 'chatbot.messengers.viber',
    [ChatBotMessengerTypes.Vk]: 'chatbot.messengers.vk',
    [ChatBotMessengerTypes.Telegram]: 'chatbot.messengers.telegram',
};

export interface ChatBotButtonProps {
    genericUrlParams: string;
    messenger: ChatBotMessengerTypes;
    href: string;
    small?: boolean;
}

const ChatBotButton: TranslatedComponent<ChatBotButtonProps> = ({ genericUrlParams, messenger, href, small, trls }) => (
    <Button
        onClick={() => window.open(href + genericUrlParams, '_blank')}
        className={classnames(styles.button, styles[`button${capitalize(messenger)}`], { [styles.buttonSmall]: small })}
    >
        {!small && trls[TrlKeys[messenger]]}
    </Button>
);

export default translation(ChatBotButton);
