import Analytics from '@hh.ru/analytics-js';
import vacancyResponseAttemptExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_attempt';

import { TopLevelSite } from 'src/models/topLevelSite';
import { UserType } from 'src/models/userType';

/**
 * Попытка отклика
 *
 * @param {object} params
 * @param {string | number} params.employerId
 * @param {string | number} params.vacancyId
 */

interface SendResponseAttemptProps {
    employerId?: number;
    vacancyId: number;
    hhtmSourceLabel?: string;
    sendExternal?: boolean;
    topLevelSite?: TopLevelSite;
    userType?: UserType;
}

export const sendResponseAttempt = ({
    employerId,
    vacancyId,
    hhtmSourceLabel,
    sendExternal = false,
    topLevelSite,
    userType,
}: SendResponseAttemptProps): void => {
    Analytics.sendHHEvent('responseAttempt', {
        vacancyId: Number(vacancyId),
        employerId: Number(employerId) || null,
        hhtmSourceLabel,
    });
    if (sendExternal && topLevelSite && userType) {
        const category = userType === UserType.Applicant ? 'applicant' : 'anonymous';
        // eslint-disable-next-line camelcase
        vacancyResponseAttemptExternal({ category, params: { product_id: vacancyId } });
        if (topLevelSite === TopLevelSite.RU) {
            Analytics.addEventPixels('B2C_VACANCY_RESPONSE_CLICK');
        }
    }
};
