import {
    MouseEvent,
    MutableRefObject,
    ReactNode,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import Analytics from '@hh.ru/analytics-js';
import { ChatikContext } from '@hh.ru/chatik-integration';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Push, usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import SendFloodlightConversion from 'HH/SendFloodlightConversion';
import { SendKardinalAnalytics } from 'HH/SendHHAnalytics';
import axios from 'HHC/Axios';
import Debug from 'HHC/Debug';
import { sendResponseAttempt } from 'Modules/VacancyAnalytics';
import scrollToElement from 'Utils/ScrollToElement';
import { AppStore } from 'src/app/store';
import buildChatikUrl from 'src/components/ChatikIntegration/utils/buildChatikUrl';
import { useNotification } from 'src/components/Notifications/Provider';
import type { AddNotification } from 'src/components/Notifications/Provider/types';
import applicantResponseError from 'src/components/Notifications/VacancyResponseError';
import { ResponseError } from 'src/components/VacancyResponseError';
import { sendAdvertisingAnalytics } from 'src/components/VacancyResponsePopup/AdvertisingPopup';
import { sendBottomSheetShownEvent } from 'src/components/VacancyResponsePopup/BottomSheet/utils';
import Source from 'src/components/VacancySearchItem/types/Source';
import { scrollWithAttention, SIGNUP_FORM_ATTENTION_CLASS } from 'src/components/VacancyView/scrolling';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import useResumeIdResponseStatuses from 'src/hooks/useResumeIdResponseStatuses';
import { useSelector } from 'src/hooks/useSelector';
import useRegisterInteraction from 'src/hooks/vacancies/useRegisterInteraction';
import {
    ShortVacancy,
    vacancyResponseSetFetching,
    vacancyResponseUpdate,
} from 'src/models/applicantVacancyResponseStatuses';
import { CountriesProfileVisibilityAgreement } from 'src/models/countriesProfileVisibilityAgreement';
import { RelocationWarning } from 'src/models/relocationWarning';
import { VacancyResponsePopup } from 'src/models/vacancyResponsePopup';
import PopupType from 'src/models/vacancyResponsePopup/popupType.types';
import fetcher from 'src/utils/fetcher';
import { sendAnythingClickAnalytics, VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import useQuickResponse, { HandleQuickResponse } from 'src/components/VacancyResponseLink/useQuickResponse';
import { getRespondedProps, RespondedText } from 'src/components/VacancyResponseLink/utils/getRespondedProps';

const relocationWarningAction = makeSetStoreField('relocationWarning');
const countriesProfileVisibilityAgreementAction = makeSetStoreField('countriesProfileVisibilityAgreement');
const vacancyResponsePopupAction = makeSetStoreField('vacancyResponsePopup');
const setVacancyResponsePopupVisible = makeSetStoreField('vacancyResponsePopupVisible');
const setLastVacancyResponseInfo = makeSetStoreField('lastVacancyResponseInfo');
const setLastQuestionResponse = makeSetStoreField('lastQuestionResponse');

interface VacancyResponseGetParams {
    vacancyId: number;
    isTest: string;
    withoutTest: string;
    lux: boolean;
    isQuestion?: boolean;

    // `startedWithQuestion` — булевый флаг, говорящий о том, что отклик начался с вопроса
    // (нужен для аналитики в модалке и на отдельной странице отклика)
    // Поле появилось, посколько на момент добавления метрик нельзя было завязываться на `isQuestion`
    // из экспериментального функционала https://jira.hh.ru/browse/PORTFOLIO-31159
    startedWithQuestion?: boolean;
    isCheckingResponseType?: boolean;
    isQuickResponseDisallowed?: boolean;
}

export enum ResponseType {
    NeedLogin = 'need-login',
    NoResumes = 'no-resumes',
    TestRequired = 'test-required',
    Reload = 'reload',
    Modal = 'modal',
    QuickResponse = 'quickResponse',
    AlreadyApplied = 'alreadyApplied',
    Question = 'question',
}

const VACANCY_RESPONSE_POPUP_URL = '/applicant/vacancy_response/popup';

export interface VacancyResponsePopupResponse {
    countriesProfileVisibilityAgreement?: CountriesProfileVisibilityAgreement;
    type: ResponseType;
    ['redirect_uri']: string;
    responseStatus: ShortVacancy;
    responsePopup: VacancyResponsePopup;
    lastResponseLetter?: string;
    lastResponseResumeHash?: string;
    relocationWarning?: RelocationWarning;
    chatId: number;
    body: {
        letterMaxLength: number;
        responseStatus: ShortVacancy;
        [`vacancy_id`]: number;
    };
}

declare global {
    interface FetcherGetApi {
        [VACANCY_RESPONSE_POPUP_URL]: {
            queryParams: VacancyResponseGetParams;
            response: VacancyResponsePopupResponse;
        };
    }
}

interface ResponseCallback {
    (): void;
}

interface FetchVacancyResponsePopupArgs extends VacancyResponseGetParams {
    handleQuickResponse: HandleQuickResponse;
    push: Push;
    employerId?: number;
    scrollToFooter?: () => void;
    responseCallbackRef?: MutableRefObject<ResponseCallback | null>;
    addNotification?: AddNotification;
    enableRelocationWarning?: boolean;
    openChatikAfterResponse?: boolean;
    hhtmFromLabel: string;
    hhtmSourceLabel?: string;
    onResponseComplete?: () => void;
    onPopupCancelCallback?: () => void;
    vrImmediateRedirectUrl?: string;
    alreadyApplied?: boolean;
    isQuestion?: boolean;
    payload?: Record<string, string>;
}

export const fetchVacancyResponsePopup =
    ({
        handleQuickResponse,
        employerId,
        scrollToFooter,
        responseCallbackRef,
        enableRelocationWarning,
        vrImmediateRedirectUrl,
        openChatikAfterResponse,
        hhtmFromLabel,
        hhtmSourceLabel,
        onResponseComplete,
        onPopupCancelCallback,
        addNotification,
        push,
        payload = {},
        ...params
    }: FetchVacancyResponsePopupArgs) =>
    (dispatch: (actions: AnyAction[] | AnyAction | ThunkAction<void, AppStore, void, AnyAction>) => void): void => {
        const handleResponse = (data: VacancyResponsePopupResponse, responseCallback: () => void) => {
            if (data.countriesProfileVisibilityAgreement?.show) {
                dispatch(countriesProfileVisibilityAgreementAction(data.countriesProfileVisibilityAgreement));
                if (responseCallbackRef) {
                    responseCallbackRef.current = responseCallback;
                }
            } else if (enableRelocationWarning && data.relocationWarning?.show) {
                dispatch(relocationWarningAction(data.relocationWarning));
                if (responseCallbackRef) {
                    responseCallbackRef.current = responseCallback;
                }
            } else {
                responseCallback();
            }
        };

        fetcher
            .get(VACANCY_RESPONSE_POPUP_URL, { params })
            .then((data) => {
                const type = data.type;
                dispatch(vacancyResponseSetFetching({ vacancyId: params.vacancyId, isFetching: false }));

                switch (type) {
                    case ResponseType.NeedLogin:
                    case ResponseType.NoResumes:
                    case ResponseType.TestRequired:
                        handleResponse(data, () => {
                            push(data.redirect_uri);
                        });
                        break;
                    case ResponseType.Reload:
                        push(window.location.href);
                        break;
                    case ResponseType.Modal:
                        handleResponse(data, () => {
                            const actions: AnyAction[] = [];
                            if (data.responseStatus) {
                                const responseStatus = {
                                    vacancyId: params.vacancyId,
                                    data: data.responseStatus,
                                };
                                actions.push(vacancyResponseUpdate(responseStatus));
                            }
                            actions.push(
                                vacancyResponsePopupAction({
                                    ...data.responsePopup,
                                    onCancelCallback: onPopupCancelCallback,
                                })
                            );
                            actions.push(setVacancyResponsePopupVisible(true));
                            if (data.responsePopup.type === PopupType.Normal) {
                                if (data.responsePopup.isQuestionResponse) {
                                    actions.push(setLastQuestionResponse(payload?.letter ?? ''));
                                } else {
                                    actions.push(
                                        setLastVacancyResponseInfo({
                                            letter: data.lastResponseLetter,
                                            resumeHash: data.lastResponseResumeHash,
                                        })
                                    );
                                }

                                sendBottomSheetShownEvent(params.vacancyId, data.responseStatus);
                            }
                            dispatch(actions);
                        });
                        break;
                    case ResponseType.QuickResponse:
                        handleResponse(data, async () => {
                            dispatch(vacancyResponseSetFetching({ vacancyId: params.vacancyId, isFetching: true }));
                            await handleQuickResponse({
                                employerId,
                                openChatikAfterResponse,
                                ...data.body,
                                ...payload,
                                hhtmFromLabel,
                                hhtmSourceLabel,
                                withoutNotification: !addNotification,
                            });
                            if (!openChatikAfterResponse && scrollToFooter) {
                                scrollToFooter();
                            }
                        });
                        break;
                    case ResponseType.Question:
                        handleResponse(data, async () => {
                            dispatch(
                                vacancyResponseSetFetching({
                                    vacancyId: params.vacancyId,
                                    isFetching: true,
                                    isFetchingWithQuestionResponse: true,
                                })
                            );
                            await handleQuickResponse({
                                employerId,
                                openChatikAfterResponse,
                                ...data.body,
                                ...payload,
                                hhtmFromLabel,
                                hhtmSourceLabel,
                                isQuestionQuickResponse: true,
                            });
                        });
                        break;
                    case ResponseType.AlreadyApplied:
                        addNotification?.(applicantResponseError, { props: { code: ResponseError.AlreadyApplied } });
                        break;
                    default:
                        addNotification?.(applicantResponseError);
                        Debug.log('out error', new Error('Unknown response type'), { data, params });
                }
            })
            .catch(() => {
                addNotification?.(applicantResponseError);
                dispatch(vacancyResponseSetFetching({ vacancyId: params.vacancyId, isFetching: false }));
            })
            .finally(() => {
                onResponseComplete?.();
            });
    };

const TrlKeys = {
    [RespondedText.OpenChat]: 'vacancy.view.response.openChat',
    [RespondedText.Chat]: 'vacancy.view.response.chat',
};

interface VacancyResponseLinkProps {
    render: (handler: (event?: MouseEvent<Element>) => void) => JSX.Element;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    place?: string;
    startedWithQuestion?: boolean;
    scrollToSignupForm?: boolean;
    renderResponded?: (params: { text: string; onClick: () => void }) => ReactNode;
    vacancyId: number;
    employerId?: number;
    doResponse?: boolean;
    enableRelocationWarning?: boolean;
    hasForceQuickResponse?: boolean;
    hasQuickResponseDisallowed?: boolean;
    vrImmediateRedirectUrl?: string;
    openChatikAfterResponse?: boolean;
    vacancySource?: Source;
    adVacancyClickUrl?: string;
    vacancyOfTheDayClickUrl?: string;
    isQuestion?: boolean;
    onResponseComplete?: () => void;
    onScrollToSignupForm?: () => void;
    onPopupCancelCallback?: () => void;
}

const VacancyResponseLink: TranslatedComponent<VacancyResponseLinkProps> = ({
    trls,
    render,
    renderResponded,
    vacancyId,
    employerId,
    doResponse,
    vacancyBodyFooterNodeRef,
    scrollToSignupForm,
    enableRelocationWarning,
    hasForceQuickResponse,
    hasQuickResponseDisallowed,
    vrImmediateRedirectUrl,
    openChatikAfterResponse,
    vacancySource,
    adVacancyClickUrl,
    vacancyOfTheDayClickUrl,
    isQuestion,
    onResponseComplete,
    onScrollToSignupForm,
    onPopupCancelCallback,
    place,
    startedWithQuestion,
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const push = usePush();
    const [kardinalAnalyticsSent, setKardinalAnalyticsSent] = useState(false);
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);
    const openChatik = useContext(ChatikContext)?.openChatik;
    const handleQuickResponse = useQuickResponse();

    const resumeIdResponseStatuses = useResumeIdResponseStatuses(vacancyId);
    const canRenderResponded = typeof renderResponded === 'function';
    const respondedProps = useMemo(() => {
        return canRenderResponded && getRespondedProps(resumeIdResponseStatuses, isMagritteVacancy);
    }, [canRenderResponded, isMagritteVacancy, resumeIdResponseStatuses]);

    const relocationWarning = useSelector((state) => state.relocationWarning);
    const countriesProfileVisibilityAgreement = useSelector((state) => state.countriesProfileVisibilityAgreement);
    const responseCallbackRef = useRef<ResponseCallback | null>(null);
    const doResponseHappened = useRef(false);
    const forceQuickResponse = useSelector(({ router }) => router.location.query?.forceQuickResponse);
    const { pathname, search } = useSelector(({ router }) => router.location);
    const { hhtmFromLabel } = useSelector(({ router }) => router.location.query) || {};
    const relatedVacancies = useSelector((state) => state.relatedVacancies);
    const getHhtmSourceLabel = useCallback(() => {
        if (vacancySource === Source.RelatedVacancies && relatedVacancies.type) {
            return `${relatedVacancies.type}_vacancies`;
        }

        return undefined;
    }, [relatedVacancies.type, vacancySource]);
    const topLevelSite = useSelector((state) => state.topLevelSite);
    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain || '');
    const userType = useSelector((state) => state.userType);
    const isZarplata = useIsZarplataPlatform();

    const scrollToFooter = useCallback(() => {
        if (vacancyBodyFooterNodeRef?.current) {
            scrollToElement(vacancyBodyFooterNodeRef.current, {
                topOffset: 0,
                centered: false,
            });
        }
    }, [vacancyBodyFooterNodeRef]);

    useEffect(() => {
        if (doResponse && !doResponseHappened.current) {
            doResponseHappened.current = true;
            dispatch(
                fetchVacancyResponsePopup({
                    vacancyId,
                    startedWithQuestion,
                    isTest: 'no',
                    withoutTest: 'no',
                    lux: true,
                    handleQuickResponse,
                    employerId,
                    scrollToFooter,
                    responseCallbackRef,
                    enableRelocationWarning,
                    hhtmFromLabel,
                    isQuestion,
                    isQuickResponseDisallowed: isQuestion,
                    onResponseComplete,
                    onPopupCancelCallback,
                    hhtmSourceLabel: getHhtmSourceLabel(),
                    addNotification,
                    push,
                })
            );
        }
    }, [
        doResponse,
        employerId,
        enableRelocationWarning,
        handleQuickResponse,
        hhtmFromLabel,
        vacancyId,
        startedWithQuestion,
        getHhtmSourceLabel,
        onResponseComplete,
        dispatch,
        scrollToFooter,
        onPopupCancelCallback,
        addNotification,
        isQuestion,
        push,
    ]);

    const alreadyApplied = !!resumeIdResponseStatuses?.usedResumeIds?.length;

    useEffect(() => {
        if (relocationWarning.confirmed && responseCallbackRef.current && relocationWarning.vacancyId === vacancyId) {
            responseCallbackRef.current();
            responseCallbackRef.current = null;
        }
    }, [relocationWarning.confirmed, relocationWarning.vacancyId, vacancyId]);

    useEffect(() => {
        if (
            countriesProfileVisibilityAgreement.confirmed &&
            responseCallbackRef.current &&
            countriesProfileVisibilityAgreement.vacancyId === vacancyId
        ) {
            responseCallbackRef.current();
            responseCallbackRef.current = null;
        }
    }, [countriesProfileVisibilityAgreement.confirmed, countriesProfileVisibilityAgreement.vacancyId, vacancyId]);

    const registerInteraction = useRegisterInteraction(vacancyId);

    const onResponseClick = useCallback(
        (event?: MouseEvent) => {
            void registerInteraction();
            if (!vrImmediateRedirectUrl) {
                event?.preventDefault();
            }

            SendFloodlightConversion({ type: 'hh_co0', cat: 'hh_bu00' });
            sendResponseAttempt({
                employerId,
                vacancyId,
                hhtmSourceLabel: vacancySource === Source.RelatedVacancies ? getHhtmSourceLabel() : place,
                sendExternal: true,
                topLevelSite,
                userType,
            });
            if (adVacancyClickUrl) {
                void axios.get(adVacancyClickUrl);
            }

            if (vacancyOfTheDayClickUrl) {
                void sendAnythingClickAnalytics({
                    location: VacancyOfTheDayLocation.VacancySearchResult,
                    clickUrl: vacancyOfTheDayClickUrl,
                });
            }

            if (scrollToSignupForm && vacancyBodyFooterNodeRef?.current) {
                if (!kardinalAnalyticsSent) {
                    void SendKardinalAnalytics({
                        goal: 'response',
                        data: { vacancy_id: vacancyId, employer_id: employerId }, // eslint-disable-line camelcase
                    });
                    setKardinalAnalyticsSent(true);
                }
                onScrollToSignupForm?.();
                scrollWithAttention(vacancyBodyFooterNodeRef.current, SIGNUP_FORM_ATTENTION_CLASS);
                return;
            }

            if (vrImmediateRedirectUrl) {
                sendAdvertisingAnalytics(vacancyId);
                if (isZarplata) {
                    Analytics.addEventPixels('ZP_PURCHASE');
                }
                return;
            }

            if (event) {
                dispatch(
                    vacancyResponseSetFetching({
                        vacancyId,
                        isFetching: true,
                        isFetchingWithQuestionResponse: isQuestion,
                    })
                );
            }

            dispatch(
                fetchVacancyResponsePopup({
                    vacancyId,
                    startedWithQuestion,
                    isTest: 'no',
                    withoutTest: 'no',
                    lux: true,
                    handleQuickResponse,
                    employerId,
                    scrollToFooter,
                    responseCallbackRef,
                    enableRelocationWarning,
                    vrImmediateRedirectUrl,
                    openChatikAfterResponse,
                    hhtmFromLabel,
                    onResponseComplete,
                    onPopupCancelCallback,
                    isQuestion,
                    isQuickResponseDisallowed: hasQuickResponseDisallowed,
                    hhtmSourceLabel: getHhtmSourceLabel(),
                    alreadyApplied,
                    addNotification,
                    push,
                })
            );
        },
        [
            registerInteraction,
            vrImmediateRedirectUrl,
            employerId,
            vacancyId,
            vacancySource,
            getHhtmSourceLabel,
            place,
            topLevelSite,
            userType,
            adVacancyClickUrl,
            vacancyOfTheDayClickUrl,
            scrollToSignupForm,
            vacancyBodyFooterNodeRef,
            dispatch,
            startedWithQuestion,
            handleQuickResponse,
            scrollToFooter,
            enableRelocationWarning,
            openChatikAfterResponse,
            hhtmFromLabel,
            onResponseComplete,
            onPopupCancelCallback,
            isQuestion,
            hasQuickResponseDisallowed,
            alreadyApplied,
            addNotification,
            push,
            kardinalAnalyticsSent,
            onScrollToSignupForm,
            isZarplata,
        ]
    );

    useEffect(() => {
        if (forceQuickResponse === 'true' && hasForceQuickResponse) {
            const currentUrl = urlParser(`${pathname}${search}`);
            const params = { ...currentUrl.params };
            delete params.forceQuickResponse;
            currentUrl.params = params;
            window.history.replaceState(window.history.state, document.title, currentUrl.href);
            onResponseClick();
        }
    }, [hasForceQuickResponse, forceQuickResponse, vacancyId, pathname, search, onResponseClick]);

    if (canRenderResponded && respondedProps) {
        const params = {
            chatId: respondedProps.chatId,
            hhtmFromLabel: place,
            topLevelDomain,
        };

        return (
            <>
                {renderResponded({
                    text: trls[TrlKeys[respondedProps.text]],
                    onClick: () => (openChatik ? openChatik(params) : window.open(buildChatikUrl(params))),
                })}
            </>
        );
    }

    return render?.(onResponseClick);
};

export default translation(VacancyResponseLink);
