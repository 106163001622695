import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    content: 'vacancyToFavorite.success',
};

const Success: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.content]}</>;

export default {
    Element: translation(Success),
    kind: 'ok',
    autoClose: true,
};
