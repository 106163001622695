// eslint-disable-file import/prefer-default-export
import scrollToElement from 'Utils/ScrollToElement';

export const RESUME_FORM_ATTENTION_CLASS = 'vacancy-resume-form_attention';
export const SIGNUP_FORM_ATTENTION_CLASS = 'signup-wrapper_attention';

export const scrollWithAttention = (resumeFormNode: Element, attentionClass: string): void => {
    scrollToElement(resumeFormNode, {
        topOffset: 0,
        centered: false,
        animationEndCallback() {
            resumeFormNode.classList.remove(attentionClass);
            window.requestAnimationFrame(() => {
                resumeFormNode.classList.add(attentionClass);
            });
        },
    });
};
