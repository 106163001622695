import { CheckmarkScaleMediumKindSingleAppearanceOutlinedEnclosedFalse } from 'bloko/blocks/icon';
import ReactIconRenderer from 'bloko/blocks/icon/IconReactRenderer';

import Components from 'HHC/Components';
import Strings from 'Utils/Strings';

import RoundTemplate from 'Modules/VacancyResponseCounter/VacancyResponseCounterRoundTemplate.mustache';
import NotifyTemplate from 'Modules/VacancyResponseCounter/VacancyResponseCounterTemplate.mustache';
import {
    applyTemplate,
    animate,
    circleComponentData,
} from 'Modules/VacancyResponseCounter/VacancyResponseCounterUtils';
import IconsTemplate from 'Modules/VacancyResponseCounter/VacancyResponseIconsTemplate.mustache';

export const CREATE_NOTIFY = 'VacancyResponseCounter_Create_Notify';

export default function VacancyResponseCounterConstructor(element, params) {
    let notifyNode = null;
    const { trls } = params;
    const { pluralizeCount } = trls;
    const words = pluralizeCount.split(',');
    const circleData = circleComponentData(60, 4);
    const renderCircleProgress = (value, limit) => {
        const circle = notifyNode.querySelector('.HH-VacancyResponseCounter-Block-Circle');
        const circleFill = circle.querySelector('.HH-VacancyResponseCounter-Block-Circle-Progress');
        if (circle && circleFill) {
            circle.setAttribute('data-progress', `${value}/${limit}`);
            circleFill.setAttribute('stroke-dashoffset', circleData.computedProgress(value, limit));
        }
    };

    const partsNotifyAnimation = {
        success: ({ roundCounter }) =>
            animate({
                delay: 200,
                duration: 1000,
                run: (rate) => {
                    const circleRound = notifyNode.querySelector('.HH-VacancyResponseCounter-Block-Round');
                    if (circleRound) {
                        const roundCount = circleRound.querySelector('.HH-VacancyResponseCounter-Block-Round-Counter');
                        circleRound.style.cssText = `
                            transform: scale(${rate})
                         `;
                        roundCount.textContent = `${roundCounter - 1}`;
                        if (rate) {
                            roundCount.textContent = `${roundCounter}`;
                        }
                    }
                },
            }),
        progress: ({ countRequired, count }) =>
            animate({
                delay: 200,
                run: () => {
                    renderCircleProgress((count - 1) * 1, countRequired);
                    animate({
                        delay: 200,
                        run: () => renderCircleProgress(count, countRequired),
                    });
                },
            }),
    };

    const updateProgressNotify = ({ partial, ...rest }) => partsNotifyAnimation[partial](rest);

    const insertNotify = (insertNode) => insertNode.appendChild(notifyNode);

    const destroyNotify = () => notifyNode.remove();

    const showNotify = (payload) =>
        animate({
            delay: 200,
            run: () => {
                notifyNode.style.cssText = `
                    max-height: 200px;
                    opacity: 1;
                `;
                updateProgressNotify(payload);
            },
        });

    const createNotify = ({ element: node, responsesCount, responsesRequired, modifyCssClass = '' }) => {
        if (!responsesCount && !responsesRequired) {
            return;
        }

        if (!node) {
            return;
        }

        if (notifyNode) {
            destroyNotify();
        }

        const container = node.querySelector('.HH-VacancyResponseCounter-Container');
        const element = container || node;
        const roundCounter = Math.floor(responsesCount / responsesRequired);
        const completeCount = Math.trunc(responsesCount - roundCounter * responsesRequired);
        const partial = completeCount === 0 && roundCounter >= 1 ? 'success' : 'progress';
        const badge =
            ['badge1', 'badge2', 'badge3', 'badge4', 'badge5', 'badge6', 'badge7'].find((badgeNumber) =>
                badgeNumber.match(Math.floor(responsesCount / responsesRequired))
            ) || 'default';
        const titleNumber =
            partial === 'success' && roundCounter > 1 ? responsesCount : responsesRequired - completeCount;

        const Template = NotifyTemplate;

        const template = applyTemplate(
            Template,
            {
                round: RoundTemplate,
                icon: IconsTemplate,
            },
            {
                [partial]: true,
                [badge]: true,
                count: completeCount,
                title: trls[partial].title.replace('{0}', `${Strings.numConversion(titleNumber, words)}`),
                description: trls[partial].description,
                circleProgress: circleData.computedProgress(
                    completeCount >= 1 ? completeCount - 1 : completeCount,
                    responsesRequired
                ),
                dashArray: circleData.dashArray,
                roundClass: roundCounter >= 1 ? '' : 'g-hidden',
                roundCounter,
                responsesRequired,
                modifyCssClass,
            }
        );
        notifyNode = template;

        if (insertNotify(element)) {
            const iconNode = notifyNode.querySelector('.HH-VacancyResponseCounter-Icon');
            if (iconNode) {
                Components.make(ReactIconRenderer, iconNode, {
                    IconComponent: CheckmarkScaleMediumKindSingleAppearanceOutlinedEnclosedFalse,
                    iconProps: {
                        initial: 'white',
                        width: 32,
                        height: 32,
                    },
                    Element: 'div',
                });
            }

            showNotify({
                partial,
                countRequired: responsesRequired,
                count: completeCount,
                roundCounter,
            });
        }
    };

    document.addEventListener(CREATE_NOTIFY, ({ detail }) => createNotify(detail));

    createNotify({ element, ...params });
}
