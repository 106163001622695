import { useRef, useEffect, useState, useCallback, MutableRefObject, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { CheckOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { CheckmarkScaleMediumKindSingleAppearanceOutlinedEnclosedFalse, IconColor } from 'bloko/blocks/icon';

import { LoopCounterStage } from 'Modules/LoopCounterParser';
import { animate, circleComponentData } from 'Modules/VacancyResponseCounter/VacancyResponseCounterUtils';

import StyledCircle from 'src/components/Applicant/LoopCounter/StyledCircle';

import styles from './loop-counter.less';

const BADGES_COUNT = 7;

interface LoopCounterProps {
    completeStepCounter: number;
    requiredStepCounter: number;
    roundCounter: number;
    stage: LoopCounterStage;
    containerData?: {
        ref: MutableRefObject<HTMLDivElement>;
        modifyCssClass: string;
    };
    isVacancyBody?: boolean;
    noAnimations?: boolean;
    isMagritteExp?: boolean;
    isSmall?: boolean;
}

const LoopCounter: FC<LoopCounterProps & PropsWithChildren> = ({
    completeStepCounter,
    requiredStepCounter,
    roundCounter,
    stage,
    containerData,
    isVacancyBody,
    noAnimations,
    isMagritteExp,
    isSmall,
}) => {
    const isProgressStage = stage === LoopCounterStage.Progress;

    const badgeSuffix = roundCounter < BADGES_COUNT ? roundCounter : BADGES_COUNT;

    const circleRef = useRef<HTMLDivElement>(null);
    const roundRef = useRef<HTMLDivElement>(null);
    const roundCounterRef = useRef<HTMLDivElement>(null);
    const circleData = circleComponentData(60, 4);
    const [circleArc, setCircleArc] = useState(() =>
        circleData.computedProgress(noAnimations ? completeStepCounter : completeStepCounter - 1, requiredStepCounter)
    );

    const renderCircleProgress = useCallback(
        (value: number, arcValue: number, limit: number) => {
            const circle = circleRef.current;
            circle?.setAttribute('data-progress', `${value}/${limit}`);
            setCircleArc(circleData.computedProgress(arcValue, limit));
        },
        [circleData]
    );

    useEffect(() => {
        if (noAnimations) {
            return;
        }

        if (containerData) {
            requestAnimationFrame(() => containerData.ref.current.classList.add(containerData.modifyCssClass));
        }

        const animateProps = isProgressStage
            ? {
                  run: (arg: number) =>
                      renderCircleProgress(completeStepCounter, completeStepCounter * (1 - arg), requiredStepCounter),
              }
            : {
                  duration: 1000,
                  run: (rate: number) => {
                      const circleRound = roundRef.current;
                      if (circleRound) {
                          circleRound.style.cssText = `
                                transform: scale(${rate})
                             `;
                          const roundCount = roundCounterRef.current;
                          if (roundCount) {
                              roundCount.textContent = `${roundCounter - 1}`;
                              if (rate) {
                                  roundCount.textContent = `${roundCounter}`;
                              }
                          }
                      }
                  },
              };

        animate({
            delay: 600,
            ...animateProps,
        });
    }, [
        isProgressStage,
        noAnimations,
        renderCircleProgress,
        requiredStepCounter,
        containerData,
        completeStepCounter,
        roundCounter,
    ]);

    if (isMagritteExp) {
        return (
            <>
                {isProgressStage ? (
                    <StyledCircle
                        ref={circleRef}
                        offset={circleArc}
                        progress={`${
                            noAnimations ? completeStepCounter : completeStepCounter - 1
                        }/${requiredStepCounter}`}
                        isMagritteExp={isMagritteExp}
                        isSmall={isSmall}
                    />
                ) : (
                    // TODO: выпилить кастомный компонент https://jira.hh.ru/browse/PORTFOLIO-32092
                    <div
                        className={classnames(styles.counterSuccessMagritte, {
                            [styles.counterSuccessMagritteSmall]: isSmall,
                        })}
                    >
                        {roundCounter > 1 ? (
                            <Text typography="title-4-semibold" style="accent">
                                x{roundCounter}
                            </Text>
                        ) : (
                            <CheckOutlinedSize24 initial="accent" />
                        )}
                    </div>
                )}
            </>
        );
    }

    return (
        <div
            className={classnames({
                [styles.counter]: true,
                [styles.counterVacancyBody]: isVacancyBody && !!roundCounter,
            })}
        >
            {isProgressStage ? (
                <StyledCircle
                    ref={circleRef}
                    offset={circleArc}
                    progress={`${noAnimations ? completeStepCounter : completeStepCounter - 1}/${requiredStepCounter}`}
                />
            ) : (
                <div className={classnames(styles.counterCircle, styles.counterCircleSuccess)}>
                    <CheckmarkScaleMediumKindSingleAppearanceOutlinedEnclosedFalse
                        initial={IconColor.White}
                        width={32}
                        height={32}
                    />
                </div>
            )}
            <div
                ref={roundRef}
                className={classnames({
                    [styles.counterRound]: true,
                    [styles.counterRoundVacancyBody]: isVacancyBody && !!roundCounter,
                    'g-hidden': isProgressStage && roundCounter < 1,
                })}
            >
                <span
                    className={classnames({
                        [styles.counterDelimiter]: true,
                        [styles.counterDelimiterVacancyBody]: isVacancyBody && !!roundCounter,
                    })}
                >
                    ×
                </span>
                <div className={styles.counterRoundDigit}>
                    {!isVacancyBody && (
                        <div
                            className={classnames({
                                [styles.counterBadge]: true,
                                [styles[`counterBadge${badgeSuffix}`]]: !!badgeSuffix,
                            })}
                        />
                    )}
                    <div ref={roundCounterRef}>{roundCounter}</div>
                </div>
            </div>
        </div>
    );
};

export default LoopCounter;
